
    import TimelineCareerItem from "@/components/timeline_items/timeline_career_item.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            TimelineCareerItem
        },
    })

    // *****************************************************************************************************************
    export default class AboutMyTimeline extends Vue {
        // Data function
        public data() {
            return {
                bitsStudios00ImagePathAndName:
                    require("@/assets/images/bits_studios_00.png"),
                bitsStudios01ImagePathAndName:
                    require("@/assets/images/bits_studios_01.png"),
                bitsStudios02ImagePathAndName:
                    require("@/assets/images/bits_studios_02.png"),
                bitsStudios03ImagePathAndName:
                    require("@/assets/images/bits_studios_03.png"),
                bitsStudiosMainImagePathAndName:
                    require("@/assets/images/bits_studios.png"),

                codemasters00ImagePathAndName:
                    require("@/assets/images/codemasters_00.png"),
                codemasters01ImagePathAndName:
                    require("@/assets/images/codemasters_01.png"),
                codemasters02ImagePathAndName:
                    require("@/assets/images/codemasters_02.png"),
                codemasters03ImagePathAndName:
                    require("@/assets/images/codemasters_03.png"),
                codemastersMainImagePathAndName:
                    require("@/assets/images/codemasters.png"),

                educationIconPathAndName:
                    require("@/assets/images/icon_education.png"),

                foxhautGames00ImagePathAndName:
                    require("@/assets/images/foxhaut_games_00.png"),
                foxhautGames01ImagePathAndName:
                    require("@/assets/images/foxhaut_games_01.png"),
                foxhautGames02ImagePathAndName:
                    require("@/assets/images/foxhaut_games_02.png"),
                foxhautGames03ImagePathAndName:
                    require("@/assets/images/foxhaut_games_03.png"),
                foxhautGamesMainImagePathAndName:
                    require("@/assets/images/foxhaut_games.png"),

                gearIconPathandName:
                    require("@/assets/images/icon_gear.png"),
                individualContributorIconPathAndName:
                    require("@/assets/images/icon_individual_contributor.png"),

                leadProgrammerIconPathAndName:
                    require("@/assets/images/icon_lead_programmer.png"),

                kuatoStudios00ImagePathAndName:
                    require("@/assets/images/kuato_studios_00.png"),
                kuatoStudios01ImagePathAndName:
                    require("@/assets/images/kuato_studios_01.png"),
                kuatoStudios02ImagePathAndName:
                    require("@/assets/images/kuato_studios_02.png"),
                kuatoStudios03ImagePathAndName:
                    require("@/assets/images/kuato_studios_03.png"),
                kuatoStudiosMainImagePathAndName:
                    require("@/assets/images/kuato_studios.png"),

                lionheadStudios00ImagePathAndName:
                    require("@/assets/images/lionhead_studios_00.png"),
                lionheadStudios01ImagePathAndName:
                    require("@/assets/images/lionhead_studios_01.png"),
                lionheadStudios02ImagePathAndName:
                    require("@/assets/images/lionhead_studios_02.png"),
                lionheadStudios03ImagePathAndName:
                    require("@/assets/images/lionhead_studios_03.png"),
                lionheadStudiosMainImagePathAndName:
                    require("@/assets/images/lionhead_studios.png"),

                magicLeapImagePathAndName:
                    require("@/assets/images/magic_leap.png"),

                managerIconPathAndName:
                    require("@/assets/images/icon_manager.png"),

                middlesexUniversity00ImagePathAndName:
                    require("@/assets/images/middlesex_university_00.png"),
                middlesexUniversity01ImagePathAndName:
                    require("@/assets/images/middlesex_university_01.png"),
                middlesexUniversity02ImagePathAndName:
                    require("@/assets/images/middlesex_university_02.png"),
                middlesexUniversity03ImagePathAndName:
                    require("@/assets/images/middlesex_university_03.png"),
                middlesexUniversityDegreeImagePathAndName:
                    require("@/assets/images/degree.png"),
                middlesexUniversityMainImagePathAndName:
                    require("@/assets/images/middlesex_university.png"),

                mindshapes00ImagePathAndName:
                    require("@/assets/images/mindshapes_00.png"),
                mindshapes01ImagePathAndName:
                    require("@/assets/images/mindshapes_01.png"),
                mindshapes02ImagePathAndName:
                    require("@/assets/images/mindshapes_02.png"),
                mindshapes03ImagePathAndName:
                    require("@/assets/images/mindshapes_03.png"),
                mindshapesMainImagePathAndName:
                    require("@/assets/images/mindshapes.png"),

                productOwnerIconPathAndName:
                    require("@/assets/images/icon_product_owner.png"),

                pupil00ImagePathAndName:
                    require("@/assets/images/pupil_00.png"),
                pupil01ImagePathAndName:
                    require("@/assets/images/pupil_01.png"),
                pupil02ImagePathAndName:
                    require("@/assets/images/pupil_02.png"),
                pupil03ImagePathAndName:
                    require("@/assets/images/pupil_03.png"),
                pupilMainImagePathAndName:
                    require("@/assets/images/pupil.png"),

                leadEngineerIconPathAndName:
                    require("@/assets/images/icon_product_owner.png"),

                virtualRetail00ImagePathAndName:
                    require("@/assets/images/virtual_retail_00.png"),
                virtualRetail01ImagePathAndName:
                    require("@/assets/images/virtual_retail_01.png"),
                virtualRetail02ImagePathAndName:
                    require("@/assets/images/virtual_retail_02.png"),
                virtualRetail03ImagePathAndName:
                    require("@/assets/images/virtual_retail_03.png"),
                virtualRetail04ImagePathAndName:
                    require("@/assets/images/virtual_retail_04.png"),

                virtualRetail05ImagePathAndName:
                    require("@/assets/images/virtual_retail_05.png"),
                virtualRetail06ImagePathAndName:
                    require("@/assets/images/virtual_retail_06.png"),
                virtualRetail07ImagePathAndName:
                    require("@/assets/images/virtual_retail_07.png"),
                virtualRetail08ImagePathAndName:
                    require("@/assets/images/virtual_retail_08.png"),
                virtualRetail09ImagePathAndName:
                    require("@/assets/images/virtual_retail_09.png"),

                ubisoft00ImagePathAndName:
                    require("@/assets/images/ubisoft.png"),
                ubisoft01ImagePathAndName:
                    require("@/assets/images/oculus_quest_3.png"),
                ubisoft02ImagePathAndName:
                    require("@/assets/images/nexus_00.png"),
                ubisoft03ImagePathAndName:
                    require("@/assets/images/nexus_01.png"),
                ubisoft04ImagePathAndName:
                    require("@/assets/images/nexus_02.png"),

                codeManagerIconPathAndName:
                    require("@/assets/images/icon_code_manager.png"),

                ndreames00ImagePathAndName:
                    require("@/assets/images/ndreams.png"),
                ndreams01ImagePathAndName:
                    require("@/assets/images/oculus_quest_3.png"),
            };
        }
    }
