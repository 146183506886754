
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class TimelineCareerItem extends Vue {
        // Const
        private readonly DEFAULT_FROM_HUE: string               = "60";
        private readonly DEFAULT_TO_HUE: string                 = "30";

        // Properties
        @Prop() private readonly fromHue!: string;
        @Prop() private readonly toHue!: string;

        @Prop() private readonly outerClassString!: string;

        @Prop() private readonly iconPathAndName!: string;
        @Prop() private readonly years!: string;
        @Prop() private readonly role!: string;
        @Prop() private readonly organisation!: string;

        @Prop() private readonly paragraph1!: string;
        @Prop() private readonly paragraph2!: string;
        @Prop() private readonly paragraph3!: string;

        @Prop() private readonly organisationImage!: string;
        @Prop() private readonly detailsImage1!: string;
        @Prop() private readonly detailsImage2!: string;
        @Prop() private readonly detailsImage3!: string;
        @Prop() private readonly detailsImage4!: string;

        // We use a computed property in case fromHue has not been set, but we still want a default value, but cannot
        // safely apply one to this.fromHue, as it will conflict with what the parent component is setting.
        private get safeFromHue(): string {
            if (this.fromHue == null) {
                return this.DEFAULT_FROM_HUE;
            }

            return this.fromHue;
        }

        // We use a computed property in case toHue has not been set, but we still want a default value, but cannot
        // safely apply one to this.toHue, as it will conflict with what the parent component is setting.
        private get safeToHue(): string {
            if (this.toHue == null) {
                return this.DEFAULT_TO_HUE;
            }

            return this.toHue;
        }

        private get upperStyleString(): string {
            return "background-image: linear-gradient(165deg, " +
                "hsla(" + this.safeFromHue + ", 100%, 30%, 0.8), " +
                "hsla(" + this.safeFromHue + ", 100%, 15%, 0.8))";
        }

        private get lowerStyleString(): string {
            return "background-image: linear-gradient(165deg, " +
                "hsla(" + this.safeFromHue + ", 100%, 15%, 0.8), " +
                "hsla(" + this.safeToHue + ", 100%, 15%, 0.4))";
        }
    }
